async function initHero() {
  const slides = document.querySelectorAll('.home-slide')
  const slidesLen = slides.length
  
  let root = {}
  
  if(!slidesLen) return
  
  let currentSlide = null
  let slideTimeout = null
  
  root.createVideo = async function (slide, i) {
    const videoWrap = slide.querySelector('.home-slide-video-wrap')
    if(!videoWrap) return null
    if(videoWrap.children.length === 0) {
      const poster = document.createElement('img')
      poster.className = 'tw-block tw-w-full tw-h-full tw-object-cover tw-object-center'
      
      if(window.innerWidth < 1024) {
        const posterMobile = videoWrap.getAttribute('data-poster-mobile').trim()
        if(posterMobile) {
          poster.src = videoWrap.getAttribute('data-poster-mobile')
        }else{
          poster.src = videoWrap.getAttribute('data-poster')
        }
        videoWrap.prepend(poster)
        return null
      }else {
        poster.src = videoWrap.getAttribute('data-poster')
      }
      
      const video = document.createElement('video')
      video.className = 'tw-block tw-w-full tw-h-full tw-object-cover tw-absolute tw-inset-0 tw-z-10'
      video.preload = 'auto'
      video.playsinline = true
      video.muted = true
      video.loop = true
      video.poster = videoWrap.getAttribute('data-poster')
      video.dataset.index = i
      const source = document.createElement('source')
      source.src = videoWrap.getAttribute('data-video')
      source.type = videoWrap.getAttribute('data-type')
      video.appendChild(source)
      
      poster.onload = () => {
        videoWrap.prepend(poster)
        videoWrap.appendChild(video)
      }
      return video
    }else {
      return videoWrap.querySelector('video')
    }
  }
  
  root.checkVideo = function(video) {
    if(video.currentTime < (video.duration - 0.7).toFixed(2)) return
    if(parseInt(video.dataset.index) !== currentSlide) return
    slideTimeout = setTimeout(() => {
      root.loadNextSlide()
      video.currentTime = 0
      video.pause()
      video.classList.remove('playing')
    }, 250)
  }
  
  root.loadPrevSlide = async function a() {
    if(slideTimeout) {
      clearTimeout(slideTimeout)
      slideTimeout = null
    }
    
    if(currentSlide === null) {
      currentSlide = slidesLen - 1
    }else {
      currentSlide--
    }
    
    const slide = slides[currentSlide]
    if(typeof slide === 'undefined' || !slide) {
      currentSlide = null
      root.loadPrevSlide()
      return
    }
    
    for(let i = 0; i < slidesLen; i++) {
      if(i === currentSlide) continue
      const s = slides[i]
      s.classList.remove('active')
    }
    slide.classList.add('active')
    
    const video = await root.createVideo(slide, currentSlide)
    
    if(!video) {
      slideTimeout = setTimeout(() => root.loadNextSlide(), 7000)
      return
    }
    
    if(!video.classList.contains('playing')) {
      await video.play()
    }
    
    if(!video.classList.contains('playing') && typeof slides[currentSlide+1] !== 'undefined' && slides[currentSlide+1]) {
      root.createVideo(slides[currentSlide+1], currentSlide+1)
    }
    
    if(!video.classList.contains('playing')) {
      video.classList.add('playing')
    }
    
    video.ontimeupdate = () => {
      root.checkVideo(video)
    }
    
  }

  root.loadNextSlide = async function b() {
    if(slideTimeout) {
      clearTimeout(slideTimeout)
      slideTimeout = null
    }
    
    if(currentSlide === null) {
      currentSlide = 0
    }else {
      currentSlide++
    }
    
    const slide = slides[currentSlide]
    if(typeof slide === 'undefined' || !slide) {
      currentSlide = null
      root.loadNextSlide()
      return
    }        
    
    for(let i = 0; i < slidesLen; i++) {
      if(i === currentSlide) continue
      const s = slides[i]
      s.classList.remove('active')
    }
    slide.classList.add('active')
    
    if(currentSlide === slidesLen - 1) {
      const navs = document.querySelectorAll('.home-slide-nav')
      for(let i = 0; i < navs.length; i++) {
        const n = navs[i]
        n.classList.remove('tw-hidden') 
      }
    }
    
    const video = await root.createVideo(slide, currentSlide)
    
    if(!video) {
      slideTimeout = setTimeout(() => root.loadNextSlide(), 7000)
      return
    }
    
    if(!video.classList.contains('playing')) {
      await video.play()
    }
    
    if(!video.classList.contains('playing') && typeof slides[currentSlide+1] !== 'undefined' && slides[currentSlide+1]) {
      const v = await root.createVideo(slides[currentSlide+1], currentSlide+1)
    }
    
    if(!video.classList.contains('playing')) {
      video.classList.add('playing')
    }
    
    video.ontimeupdate = () => {
      root.checkVideo(video)
    }
    
  }
  
  root.loadNextSlide()
  
  return root
}

export { initHero }